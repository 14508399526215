.card-header {
    background-color: rgba(0, 0, 0, 0.03);
}
.card-footer {
    background-color: rgba(0, 0, 0, 0.03) !important;
    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.CListGroup {
    border-radius: .25rem;
    -webkit-box-shadow: 0 0 0 0 #6c757d;
    box-shadow: 0 0 0 0 #6c757d;
    margin-bottom: 1rem !important;
}

.CListGroupItemHeader {
    font-size: 1em !important;
    font-weight: 600 !important;
    text-align: left !important;
    width: auto;
    padding: 0 10px;
    border-bottom: none;
    color: rgba(51,122,183,1)
}

.form-check {
    padding-left: 2em !important;
}
.form-check-input {
    width: 1.2em !important;
    height: 1.2em !important;
    margin-top: 0.15em !important;
}

.fa-whatsapp {
    color:#008069;
}

.card-accent-primary {
    border-top: 2px solid #321fdb !important
}

.card-accent-secondary {
    border-top: 2px solid #ced2d8 !important
}

.card-accent-success {
    border-top: 2px solid #2eb85c !important
}

.card-accent-info {
    border-top: 2px solid #39f !important
}

.card-accent-warning {
    border-top: 2px solid #f9b115 !important
}

.card-accent-danger {
    border-top: 2px solid #e55353 !important;
}
.card-accent-light {
    border-top: 2px solid #ebedef !important
}

.card-accent-dark {
    border-top: 2px solid #636f83 !important;
}

.box-shadow {
    -webkit-box-shadow: 0px 10px 20px 0px rgb(50 50 50 / 52%);
    -moz-box-shadow: 0px 10px 20px 0px rgba(50, 50, 50, 0.52);
    box-shadow: 0px 10px 20px 0px rgb(50 50 50 / 52%);
}

body {
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto, helvetica neue, Arial, noto sans, sans-serif, apple color emoji, segoe ui emoji, segoe ui symbol, noto color emoji;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
}

.c-avatar_carnet {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 5em;
    width: 140px;
    height: 140px;
    font-size: 14.4px;
}

.text-value-lg {
    font-size: 1.3125rem;
}

[class^=text-value] {
    font-weight: 600;
}

#react-qrcode-logo {
    padding: 0;
}

.productos-card {
    background-color: rgb(255, 255, 255);
    color: rgb(33, 43, 54);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 12px;
    position: relative;
    z-index: 0;
}

.productos-precios {
    margin: 0px;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1rem;
    font-family: "Public Sans", sans-serif;
}

.productos-contenido {
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.productos-titulo {
    font-weight: 600;
    line-height: 1.57143;
    font-size: 0.875rem;
}

.whatsapp-icon {
    color:#25d366
}

.Alineadoabajo {
    vertical-align: bottom;
    
}

.cambiocard {
    --cui-card-spacer-x:4rem !important
}


.colortextoalertnegro
{
    color: black !important;
    text-decoration: solid;
}
.Carnetavatar-xl >
.avatar-img  {
    width: 100%;
    height: 100% !important;
 
}

.Carnetavatar-xl {
    width: 6rem !important;
    height: 6rem !important;
    font-size: 1.6rem !important;
    border-radius: 50%;


}

.pepe  {
border-radius:50%;

}
/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp-icon {
        margin-top: 10px;
    }
}

.danger {
    color: #e55353;
}

.success {
    color: #2eb85c;
}